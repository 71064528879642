<script>
  import {useStore} from "vuex";
  import ColorGuide from "@/components/ColorGuideTrafficLight";
  import ColorGuideDesc from "@/components/ColorGuideTrafficLightDesc";

export default {
  setup(){
    const currentYear =  process.env.VUE_APP_YEAR || 2020;
    const store = useStore();
    const countries = store.state.countries;
    const indices1  = store.state.indexGuide.filter(d => d.axis == 1);
    const indices2  = store.state.indexGuide.filter(d => d.axis == 2);
    const indices3  = store.state.indexGuide.filter(d => d.axis == 3);
    const direction = index => index.direction.indexOf("up") != -1 ? '&#x2191;' : '&#x2193;';

    return {
      countries,
      indices1,
      indices2,
      indices3,
      getData : store.getters.getValueFromIndexWithCountryAndYear,
      format  : store.getters.formatNumber,
      color   : store.getters.getColor,
      year    : currentYear,
      direction,
      currentYear
    }
  },

  components : {
      ColorGuide,
      ColorGuideDesc
    }
}
</script>
<template>
<section>
    <div class="container">
        <div class="col-12">
            <h1 class="mb-4">Estatus de la región</h1>

             <!-- covid-->
        <div class="row mt-4">
          <div class="col-12">
            <div class="ip_separator bottom"></div>
            <h2>VIH y COVID-19 en América Latina</h2>
            <h3>Buenas prácticas y lecciones aprendidas para el ajuste de la respuesta de los estados</h3>
            <p>América Latina es uno de los continentes más golpeados por la pandemia y la región ya era la más desigual del planeta. El Covid-19 marcó la fragilidad del sistema de salud y la respuesta al Vih se vio debilitada ante la entrega y la atención integral, hubo interrupciones en los servicios de atención, se evidenció el desgaste y fallo progresivo en los servicios de salud en la región, se vulneraron los derechos constitucionales y se está ante una coyuntura de reconfiguración de espacios de toma de decisiones y fuerzas sociales, que en términos generales se concreta en retrocesos y pérdida de espacios de participación.</p>
            <p><a href="/archivos/pandemia-sobre-pandemia.pdf" class="od_dl_btn">Descarga el informe</a></p>
          </div>
        </div>
                    <div class="ip_separator bottom"></div>

          <p>A continuación se muestra el progreso del país a través de los tres ejes de Observadatos. El color depende del porcentaje alcanzado en cada uno de los objetivos (conocimiento del estado serológico, recepción de TARV y supresión de carga viral) y va de gris -en donde no hay información disponible- a azul -en donde el porcentaje es igual o mayor a 90.</p>
    

        <h2 class="mt-4 pt-4">Eje 1: Eficiencia del gasto en VIH</h2>

        <div class="row mt-4">
            <div class="col-sm-5">
                <h5>Metas Ascendentes &#x2191; </h5>
                <ColorGuide/>
            </div>
            <div class="col-sm-5 offset-sm-1">
                <h5>Metas Descendentes &#x2193; </h5>
                <ColorGuideDesc/>
            </div>
        </div>
        
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th class="od_p_5"><span class="od_t_70">Sentido</span></th>
                  <th class="od_p_5" v-for="country of countries" :key="`head-${country.id}`">
                    <span class="od_t_70">{{country.name}}</span>
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="index of indices1" :key="`table-${index.db_name}`">
                  <td>{{index.name}}</td>
                  <td class="od_p_5 text-center im_label direction" v-html="direction(index)"></td>  
                  <td class="od_p_5" v-for="country of countries" :key="`table-${index.db_name}-${country.id}`">
                    <div :class="`${color(index.db_name, getData(index.db_name, country.id, year))} _bg`">
                      {{getData(index.db_name, country.id, year) != null ? format(getData(index.db_name, country.id, year))+'%' : ''}}
                    </div>
                  </td>
                   
                </tr>
              </tbody>
            </table>
          </div>
          <!--source-->
              <div class="row">
                <div class="col-sm-6 offset-sm-6">
                  <p class="od_source text-right">Información correspondiente a {{currentYear}}. Fuentes: Onusida, Banco Mundial, Informes GAM, portales de contrataciones de los gobiernos.</p>
                </div>
              </div>

            <h2 class="mt-4">Eje 2: Pertinencia de la inversión</h2>
            <div class="row mt-4">
            <div class="col-5">
                <h4>Metas Ascendentes &#x2191; </h4>
                <ColorGuide/>
            </div>
            <div class="col-5 offset-sm-1">
                <h4>Metas Descendentes &#x2193; </h4>
                <ColorGuideDesc/>
            </div>
        </div>
        
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th class="od_p_5"><span class="od_t_70">Sentido</span></th>
                  <th class="od_p_5" v-for="country of countries" :key="`head-${country.id}`">
                    <span class="od_t_70">{{country.name}}</span>
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="index of indices2" :key="`table-${index.db_name}`">
                  <td>{{index.name}}</td>
                  <td class="od_p_5 text-center im_label direction" v-html="direction(index)"></td>   
                  <td class="od_p_5" v-for="country of countries" :key="`table-${index.db_name}-${country.id}`">
                    <div :class="`${color(index.db_name, getData(index.db_name, country.id, year))} _bg`">
                      {{getData(index.db_name, country.id, year) != null ? format(getData(index.db_name, country.id, year))+'%' : ''}}
                    </div>
                  </td>
                  
                </tr>
              </tbody>
            </table>
          </div>
          <!--source-->
              <div class="row">
                <div class="col-sm-6 offset-sm-6">
                  <p class="od_source text-right">Información correspondiente a {{currentYear}}. Fuentes: Onusida, Banco Mundial, Informes GAM, portales de contrataciones de los gobiernos.</p>
                </div>
              </div>

             <h2 class="mt-4">Eje 3: Servicios</h2>
             <div class="row mt-4">
            <div class="col-5">
                <h4>Metas Ascendentes &#x2191; </h4>
                <ColorGuide/>
            </div>
            <div class="col-5 offset-sm-1">
                <h4>Metas Descendentes &#x2193; </h4>
                <ColorGuideDesc/>
            </div>
        </div>
        
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th class="od_p_5"><span class="od_t_70">Sentido</span></th>
                  <th class="od_p_5" v-for="country of countries" :key="`head-${country.id}`">
                    <span class="od_t_70">{{country.name}}</span>
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="index of indices3" :key="`table-${index.db_name}`">
                  <td>{{index.name}}</td>
                  <td class="od_p_5 text-center im_label direction" v-html="direction(index)"></td>   
                  <td class="od_p_5" v-for="country of countries" :key="`table-${index.db_name}-${country.id}`">
                    <div :class="`${color(index.db_name, getData(index.db_name, country.id, year))} _bg`">
                      {{getData(index.db_name, country.id, year) != null ? format(getData(index.db_name, country.id, year))+'%' : ''}}
                    </div>
                  </td>
                  
                </tr>
              </tbody>
            </table>
          </div>
           <!--source-->
              <div class="row">
                <div class="col-sm-6 offset-sm-6">
                  <p class="od_source text-right">Información correspondiente a 2020. Fuentes: Onusida, Banco Mundial, Informes GAM, portales de contrataciones de los gobiernos.</p>
                </div>
              </div>
        </div>

        

       
    </div>
</section>
</template>
